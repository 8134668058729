html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-headerContact {
  min-height: 100vh;
  background-color: rgb(248, 249, 250); /* Background color for contact page */
}

.hover-logo {
  position: relative; /* Allows positioning for the underline */
  overflow: hidden; /* Hides any overflow */
  border-radius: 10px; /* Slightly rounded corners */
  padding: 20px; /* Add padding for visual consistency */
  transition: transform 0.3s ease; /* Smooth scaling transition */
  cursor: default; /* Set cursor to default */
  border: 1px solid #fff; /* Set the border to white */
  color: #fff;
}

.hover-logo h3 {
  position: relative; /* Position relative to allow absolute positioning for the underline */
  display: inline-block; /* Aligns the underline with the text */
  margin-bottom: 10px; /* Space between text and underline */
  border-color: #fff;

}

.hover-logo h3::after {
  content: ''; /* Create an underline effect */
  position: absolute; /* Position it absolutely under the text */
  left: 0; /* Align to the left */
  bottom: 0; /* Align to the bottom of the text */
  height: 2px; /* Height of the underline */
  width: 100%; /* Full width of the text */
  background-color: rgb(178, 178, 178); /* Color of the underline */
  transform: scaleX(0); /* Start hidden */
  transition: transform 0.3s ease; /* Smooth transition for appearance */
  border-color: #fff;

}

.hover-logo:hover h3::after {
  transform: scaleX(1); /* Scale to full width on hover */
  border-color: #fff;

}

.hover-logo:hover {
  transform: scale(1.001); /* Scale effect on hover */
  cursor: default; /* Set cursor to default */
  border-color: #fff;

}

@media (max-width: 767px) {
  .hover-logo {
    min-height: 100px; /* Adjust as needed for mobile */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border-color: #fff;

  }
}

.fixed-size {
  width: 200px; /* Set fixed width */
  height: 100px; /* Set fixed height */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-background-footer {
  position: relative; /* Position for pseudo-elements */
  overflow: hidden; /* Prevent overflow of animated elements */
}

/* Base styles for the app background */
.app-background {
  position: relative; /* Position for pseudo-elements */
  overflow: hidden; /* Prevent overflow of animated elements */
  min-height: 100vh; /* Ensure it covers the full height */
}

.app-background-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #000000, #666666, #a29d9d, #000000);
  background-size: 400% 400%; /* Set the size for animation */
  animation: gradientAnimation 15s ease infinite; /* Animation for the background */
  z-index: -1; /* Behind other content */
}

/* Animated gradient background */
.app-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #000000, #666666, #a29d9d, #000000);
  background-size: 400% 400%; /* Set the size for animation */
  animation: gradientAnimation 15s ease infinite; /* Animation for the background */
  z-index: -1; /* Behind other content */
}

/* Keyframes for the gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Override default focus styles for input and textarea elements within the contact form */
.bg-white input:focus, 
.bg-white textarea:focus {
  outline: none; /* Remove the default outline */
  box-shadow: 0 0 0 2px rgba(105, 105, 105, 0.5); /* Add a dark grey shadow */
  border-color: darkgrey; /* Change the border color to dark grey */
}

/* .cursor-dot {
  position: fixed;
  pointer-events: none; 
  transition: transform 0.1s ease; 
} */

